import { useEffect, useMemo, useState } from 'react';
import cn from "classnames";
import { CriteriaEstimateManagerHook } from "@/hooks/useCriteriaAssetsManager";
import PendingClockIcon from "@/assets/icons/pending-clock.svg?react";
import CircleCheck from "@/assets/icons/circle-check.svg?react";

const AudienceEstimateLoading = ({ criteriaAssetsManager }: { criteriaAssetsManager: CriteriaEstimateManagerHook }) => {
    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        isDone: false,
        isHiding: false,
    });

    const pendingLoading = useMemo(() => ([
        ...(!criteriaAssetsManager.isLoadingSize && criteriaAssetsManager.isLoadingMatchRate ? ["Match Rate"] : []),
        ...(criteriaAssetsManager.isLoadingSize || criteriaAssetsManager.isLoadingSomeSummary ? ["Summary"] : []),
        ...(criteriaAssetsManager.isLoadingPreview ? ["Preview"] : []),
        ...(criteriaAssetsManager.isLoadingSize ? ["Size"] : []),
    ]), [criteriaAssetsManager]);

    useEffect(() => {
        if (pendingLoading.length > 0) {
            setLoadingState({ isLoading: true, isDone: false, isHiding: false });
        } else if (loadingState.isLoading && pendingLoading.length === 0) {
            setLoadingState({ isLoading: false, isDone: true, isHiding: false });

            setTimeout(() => setLoadingState(state => ({ ...state, isHiding: true })), 2300);
            setTimeout(() => setLoadingState({ isLoading: false, isDone: false, isHiding: false }), 3000);
        }
    }, [pendingLoading, loadingState.isLoading]);

    return (loadingState.isLoading || loadingState.isDone) && (
        <div className={cn("flex gap-2 text-sm items-center overflow-hidden px-4 py-2 rounded-lg mr-2 duration-700 transition-all w-[350px] text-ui-700 bg-ui-50", {
            "!w-0 !px-0": loadingState.isDone && loadingState.isHiding,
            "animate-pulse": !loadingState.isDone,
        })}>
            {loadingState.isLoading && <>
                <PendingClockIcon className="min-w-5 min-h-5" />
                <span className="text-sm">Loading {pendingLoading.join(', ')}...</span>
            </>}
            {loadingState.isDone && <>
                <CircleCheck className="w-5 h-5" />
                <span className="text-sm">Done!</span>
            </>}
        </div>
    )
}

export default AudienceEstimateLoading;
