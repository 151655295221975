import { capitalize, isEmpty, isNil } from "lodash";

import { AudienceSync, AudienceSyncStatus } from "@/types/api";
import { formatTimeToShortNotation, getTimeDifference } from "@/utils/date";

import { RunEvent } from "./RunEvent";
import { DurationTimer } from "./DurationTimer";
import Markdown from "markdown-to-jsx";
import { Fragment, MouseEvent } from "react";
import { useGetOnboardingQuery, useUpdateOnboardingMutation } from "@/api/users";
import { FeatureFlagsEnum, useFeatureFlag } from "@/hooks";

type Props = {
    sync: AudienceSync;
    audienceName: string;
    isLatest?: boolean;
    peopleCount?: number | null;
};

const SyncEvent = ({ sync, isLatest, peopleCount }: Props) => {
    const { createdAt, finishedAt, status, destination, error, adAudienceUrl, stats } = sync;
    const destinationName = capitalize(destination.replace("facebook", "meta"));

    const duration = isNil(finishedAt) ? (
        <DurationTimer startTime={new Date(createdAt)} />
    ) : (
        formatTimeToShortNotation(getTimeDifference(new Date(createdAt), new Date(finishedAt)))
    );

    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);
    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });
    const [updateOnboarding] = useUpdateOnboardingMutation();

    const showAudienceAtDestinationPlatform = async (e: MouseEvent) => {
        e.preventDefault();
        if (onboarding && !onboarding.ad_destination_visited) {
            await updateOnboarding({ id: onboarding!.id, ad_destination_visited: true });
        }
        window.open(adAudienceUrl as string, "_blank");
    };

    const details =
        status === AudienceSyncStatus.FINISHED ? (
            <span>
                <span>
                    {isEmpty(error) ? (
                        <a className="text-sm text-blue-800 cursor-pointer" onClick={showAudienceAtDestinationPlatform}>
                            Audience
                        </a>
                    ) : (
                        "Audience"
                    )}{" "}
                    sync {!isEmpty(error) ? "failed" : "succeeded"}
                </span>
                {!isEmpty(error) && (
                    <p className="text-error">
                        <Markdown
                            children={error!}
                            options={{
                                wrapper: Fragment,
                                overrides: { a: { props: { target: "_blank", className: "underline" } } },
                            }}
                        ></Markdown>
                    </p>
                )}
            </span>
        ) : (
            <span className="text-ui-300">Running</span>
        );

    return (
        <RunEvent
            eventName={`Sync to ${destinationName}`}
            duration={duration}
            details={details}
            success={!isEmpty(finishedAt) && isEmpty(error)}
            inProgress={isEmpty(error) && isEmpty(finishedAt)}
            error={error}
            adAudienceUrl={adAudienceUrl}
            stats={stats}
            isLatest={isLatest}
            peopleCount={peopleCount}
            destination={destination}
        />
    );
};

export default SyncEvent;
