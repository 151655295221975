import { ExtraChartSchema, SummaryMetricColumn } from "@/types/audience";
import React from "react";
import posthog from "posthog-js";
import cn from "classnames";
import "./style.css";
import { SummaryCriteriaChangeManagerResponse } from "@/hooks/useSummaryCriteriaChangeManager";
import SummaryRow from "./SummaryRow";
import { CriteriaEstimateManagerHook } from "@/hooks/useCriteriaAssetsManager";

export type SummaryBoxProps = {
    summary: ExtraChartSchema;
    expand: () => void;
    allowExpand: boolean;
    isExpanding?: boolean;
    summaryChangeStateManager: SummaryCriteriaChangeManagerResponse;
    criteriaAssetsManager: CriteriaEstimateManagerHook;
}

const SummaryBox: React.FC<SummaryBoxProps> = (props: SummaryBoxProps) => {
    const { summary } = props;
    const [scrolled, setScrolled] = React.useState<string[]>([]);

    const handleScroll = () => {
        if (!!summary?.category && !scrolled.includes(summary.category)) {
            posthog.capture(`Scrolled "${summary.category}"`);
            setScrolled(prev => [...prev, summary.category!]);
        }
    };

    const parseCategoryName = (category?: string): string | undefined => {
        if (category === SummaryMetricColumn.SENIORITY) return "Seniority Level";
        return category?.toString();
    }

    React.useEffect(() => setScrolled([]), [summary]);

    return (
        <div className="show-scroll">
            <div className="mb-[8px] font-medium uppercase text-xs text-ui-700">{parseCategoryName(summary.name)}</div>
            <div
                className={cn("border b-1 rounded-md border-ui-100 p-[4px] h-[278px]", {
                    "pr-[16px] overflow-hidden hover:overflow-y-scroll hover:pr-[4px]": summary.values.length > 8,
                    "bg-ui-50": summary.values.length === 0,
                    "hover:border-blue-300": summary.values.length > 0,
                })}
                onScroll={handleScroll}>
                {summary.values.length === 0 && <div className="text-ui-300 w-full h-full flex items-center justify-center text-sm"><span>There are no values to display.</span></div>}
                <div className="w-full flex flex-col gap-[2px]">
                    {summary.values.map((value) => (
                        <SummaryRow {...props} summaryValue={value} key={value.key} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SummaryBox;
