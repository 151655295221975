import cn from "classnames";
import postHog from "posthog-js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FeatureFlagsEnum, useFeatureFlag, useSearch } from "@/hooks";
import { CreateOAuthConnection } from "@/types/api";
import { Connection, Service } from "@/types/integrations";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg?react";
import { getIconForService, getNameForService } from "@/config/integrations";
import {
    useGetConnectionsQuery,
    useOauthConnectMutation,
    useRetryConnectionMutation,
    useToogleConnectionMutation,
} from "@/api/integrations";

import { Search } from "@/components/molecules/Search";
import { Button } from "@/components/atoms/Button/Button";

import { useGetOnboardingQuery, useUpdateOnboardingMutation } from "@/api/users";
import { SelectAccount, SelectAccountsSkeleton } from "@/components/organisms/integrations/SelectAccount";
import { EmptyAccountListWarning } from "@/components/organisms/ErrorMessage";
import { capitalize } from "lodash";
import { useToast } from "@/components/atoms/Toast/useToast.tsx";

export const ConnectPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onboardingPitchEnabled = useFeatureFlag(FeatureFlagsEnum.ONBOARDING_PITCH);

    const [selectedAccount, selectAccount] = useState<string>();
    const [selectedAccountTosAccepted, setSelectedAccountTosAccepted] = useState<boolean>(false);
    const [oauthConnections, setOauthConnections] = useState<Connection[]>();

    const [searchValue, searchTerm, setSearchValue] = useSearch("");
    const [loading, setLoading] = useState(true);
    const [retryLoading, setRetryLoading] = useState(false);
    const [retryFailed, setRetryFailed] = useState(false);

    const code = searchParams.get("code") || "";
    const service = id ? Object.values(Service).find(s => s === id.toLowerCase()) : undefined;

    const { data: onboarding } = useGetOnboardingQuery(undefined, { skip: !onboardingPitchEnabled });
    const redirectToOnboarding = onboarding && !onboarding.onboarding_completed;
    const onboardingStep = service === Service.SALESFORCE ? 2 : 3;
    const redirectUrl = redirectToOnboarding ? `/onboarding?step=${onboardingStep}` : "/integrations";

    const [updateOnboarding] = useUpdateOnboardingMutation();

    const connecting = useRef(false);
    const { toast, dismiss } = useToast();

    const { data: connections } = useGetConnectionsQuery();
    const [toogleConnectionMutation] = useToogleConnectionMutation();

    const toogleConnection = async (req: { connectionId: string | number; action: "enable" | "disable" }) =>
        toogleConnectionMutation(req).unwrap();

    const [oAuthConnectMutation] = useOauthConnectMutation();
    const oAuthConnect = async (req: CreateOAuthConnection) => oAuthConnectMutation(req).unwrap();

    const [retryConnectionMutation] = useRetryConnectionMutation();
    const retryConnection = async (req: { id: string }) => retryConnectionMutation(req).unwrap();

    if (!service) navigate(redirectUrl);

    useEffect(() => {
        const connect = async () => {
            try {
                dismiss();
                const created = await oAuthConnect({ code, provider: service! });
                setOauthConnections(created);
                setLoading(false);
            } catch (err) {
                navigate(redirectUrl);
                toast({
                    description: (err as any)?.data || (err as any)?.message || "Please try again.",
                    variant: "destructive",
                });
            }
        };

        if (connecting.current) return;
        connecting.current = true;
        connect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retry = async () => {
        if (!selectedAccount) return;

        try {
            setRetryLoading(true);
            const result = await retryConnection({ id: selectedAccount });
            if (result) {
                setOauthConnections(previous => {
                    const current = oauthConnections?.find(conn => conn.id === selectedAccount);
                    if (!current) return previous;

                    current.provider_tos_accepted = true;
                    return oauthConnections;
                });

                setSelectedAccountTosAccepted(true);

                select();
            } else {
                setRetryFailed(true);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setRetryLoading(false);
        }
    };

    const select = async () => {
        if (!selectedAccount) return;
        try {
            await Promise.all(
                (connections?.filter(({ provider }) => provider === service) || []).map(({ enabled, id }) => {
                    if (enabled) return toogleConnection({ connectionId: id, action: "disable" });
                }),
            );

            const data = await toogleConnection({ connectionId: selectedAccount, action: "enable" });
            const error = data?.error?.message;

            postHog.capture(`Completed Integration - ${service}`, {
                ...data,
            });

            if (redirectToOnboarding) {
                const updatePayload =
                    Service.SALESFORCE === service ? { crm_connected: true } : { add_platform_connected: true };
                !error && (await updateOnboarding({ id: onboarding.id, ...updatePayload }));
                navigate(redirectUrl);
            } else {
                navigate(`${redirectUrl}/${service}/settings`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const cancel = () => navigate(redirectUrl);

    const filteredConnections = useMemo(
        () => oauthConnections?.filter(({ provider_account_id }) => provider_account_id.includes(searchTerm)),
        [searchTerm, oauthConnections],
    );

    return (
        <div className="w-full h-full px-12 py-20 flex flex-col justify-top items-center">
            {!loading && filteredConnections?.length === 0 ? (
                <EmptyAccountListWarning serviceName={capitalize(service!)} onCancel={cancel} />
            ) : (
                <>
                    <h1 className="text-ui-700 text-2xl">Select {getNameForService(service!)} Accounts</h1>

                    <div className="w-[700px] px-8 py-10 mt-6 flex-flex-col items-center justify-start bg-white shadow-main rounded-2xl space-y-12">
                        {/* header */}
                        <div className="flex gap-y-4 w-full items-center">
                            {getIconForService(service!, "size-9 mr-3")}
                            <Search
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                                title="Accounts search"
                                placeholder="Search accounts"
                            />
                        </div>

                        {/* main */}
                        <div
                            className={cn(
                                "flex flex-col justify-start items-center w-full overflow-auto space-y-2 transition-all duration-1000",
                                {
                                    "max-h-60": loading,
                                    "max-h-[500px]": !loading,
                                },
                            )}
                        >
                            <div className="flex items-center justify-between w-full">
                                <span className="text-xs font-medium text-ui-700">ACCOUNT ID</span>
                            </div>
                            {loading && <SelectAccountsSkeleton />}

                            <div
                                className={cn("flex flex-col gap-2 h-full w-full transition-opacity duration-1000", {
                                    "opacity-0": loading,
                                    "opacity-100": !loading,
                                })}
                            >
                                {filteredConnections?.map(
                                    ({
                                        id,
                                        provider_organization_name,
                                        provider_tos_accepted,
                                        provider_account_id,
                                        error,
                                    }) => (
                                        <SelectAccount
                                            error={error}
                                            id={provider_account_id}
                                            key={provider_account_id}
                                            checked={selectedAccount === id}
                                            provider_organization_name={provider_organization_name ?? null}
                                            provider_tos_accepted={provider_tos_accepted ?? true}
                                            retryLoading={retryLoading}
                                            retryFailed={retryFailed}
                                            onCheckClick={() => {
                                                if (!error) selectAccount(id);
                                                setSelectedAccountTosAccepted(provider_tos_accepted ?? true);
                                            }}
                                            onRetryClick={retry}
                                        />
                                    ),
                                )}
                            </div>
                        </div>

                        {/* footer */}
                        <div className="flex justify-end">
                            <div className="flex items-center space-x-4">
                                <Button variant="secondary" onClick={cancel}>
                                    Cancel
                                </Button>
                                <Button onClick={select} disabled={!selectedAccount || !selectedAccountTosAccepted}>
                                    Continue <ArrowRightIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
