import { useMemo } from "react";
import { FeatureFlagsEnum, useFeatureFlag } from "./useFeatureFlag";
import { FilterConfig, FilterDataTypes, FilterFields, FilterOperators, OperatorConfig } from "@primer/filters/types";
import { getAvailableOperators } from "@/utils/filter";

export const useOperator = ({
    selectedFilterField,
    selectedOperator,
}: {
    selectedFilterField?: FilterConfig;
    selectedOperator?: OperatorConfig;
}) => {
    const repoV2Enabled = useFeatureFlag(FeatureFlagsEnum.REPO_RC);

    const operators = useMemo(
        () => getAvailableOperators(selectedFilterField, repoV2Enabled),
        [selectedFilterField, repoV2Enabled],
    );

    const isMultiNumber = useMemo(
        () =>
            selectedFilterField?.dataType === FilterDataTypes.NUMBER &&
            selectedFilterField?.identifier &&
            (!selectedOperator?.id || [FilterOperators.IS, FilterOperators.IS_NOT].includes(selectedOperator.id)) &&
            [FilterFields.ANNUAL_REVENUE.toString(), FilterFields.HEADCOUNT.toString()].includes(
                selectedFilterField.identifier,
            ),
        [selectedFilterField, selectedOperator],
    );

    return {
        operators,
        isMultiNumber,
    };
};
