import { api } from "@/api";
import {
    GetEstimatePreviewRequest,
    GetEstimatePreviewRawResponse,
    GetEstimateSizeRawResponse,
    GetEstimateSizeRequest,
    GetEstimateSummaryRequest,
    GetEstimateSummaryResponse,
    SaveEstimateSummaryRequest,
    GetEstimateMatchRateRawResponse,
    GetEstimateMatchRateRequest,
} from "@/types/api";
import queryString from "query-string";

export const audiencesApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getCriteriaEstimateSize: builder.mutation<GetEstimateSizeRawResponse, GetEstimateSizeRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return {
                    url: `/criterias/estimate/size?${serializedQuery}`,
                    getMaxTries: () => 2,
                };
            },
        }),
        getCriteriaEstimatePreview: builder.mutation<GetEstimatePreviewRawResponse, GetEstimatePreviewRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return {
                    url: `/criterias/estimate/preview?${serializedQuery}`,
                    getMaxTries: () => 2,
                };
            },
        }),
        getCriteriaEstimateMatchRate: builder.mutation<GetEstimateMatchRateRawResponse, GetEstimateMatchRateRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return {
                    url: `/criterias/estimate/match-rate?${serializedQuery}`,
                    getMaxTries: () => 2,
                };
            },
        }),
        getCriteriaEstimateSummary: builder.mutation<GetEstimateSummaryResponse[], GetEstimateSummaryRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return {
                    url: `/criterias/estimate/summary?${serializedQuery}`,
                    getMaxTries: () => 2,
                };
            },
        }),
        saveEstimate: builder.mutation<void, SaveEstimateSummaryRequest>({
            query: ({ audience_id, shape_id, ...body }) => ({
                url: `/criterias/estimate?audience_id=${audience_id}&shape_id=${shape_id}`,
                method: "PATCH",
                body,
            }),
        }),
    }),
});

export const {
    usePrefetch,
    useGetCriteriaEstimateSizeMutation,
    useGetCriteriaEstimatePreviewMutation,
    useGetCriteriaEstimateMatchRateMutation,
    useGetCriteriaEstimateSummaryMutation,
    useSaveEstimateMutation,
} = audiencesApiSlice;
