/* eslint-disable react-hooks/exhaustive-deps */
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/atoms/DropdownMenu/DropdownMenu";
import Radio from "@/components/atoms/Radio/Radio";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { useOperator } from "@/hooks/useOperator";
import { getOperatorName } from "@/utils/filter";
import { filterOperatorConfigs } from "@primer/filters/configs";
import { Filter, FilterConfig, FilterDataTypes, FilterOperators, OperatorConfig, SourceCriteriaFilterValue } from "@primer/filters/types";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import React from "react";

interface FilterCriteriaOperatorProps {
    selectedFilterField: FilterConfig;

    selectedOperator: OperatorConfig;
    setSelectedOperator: (value: OperatorConfig) => void;

    newFilter: Partial<Filter>;
    setNewFilter: (value: Partial<Filter>) => void;

    selectOpen: boolean;
    setSelectOpen: (value: boolean) => void;

    setSelectedValues: React.Dispatch<React.SetStateAction<SourceCriteriaFilterValue[] | undefined>>;
}

export const FilterCriteriaOperator = ({ selectedFilterField, setSelectedValues, selectedOperator, setSelectedOperator, newFilter, setNewFilter, selectOpen, setSelectOpen }: FilterCriteriaOperatorProps) => {
    const [filterOperators, setFilterOperators] = React.useState<OperatorConfig[]>([]);
    const { operators } = useOperator({ selectedFilterField, selectedOperator });

    React.useEffect(() => {
        const operatorConfigs = operators.map(operator => filterOperatorConfigs[operator]) ?? [];
        setFilterOperators(operatorConfigs);
    }, [operators]);

    const onOperatorChange = (value: string) => {
        const newOperator = value as FilterOperators;
        const shouldResetValues =
            [FilterOperators.IS, FilterOperators.IS_NOT].includes(newOperator)
            && ![FilterOperators.IS, FilterOperators.IS_NOT].includes(selectedOperator.id)
            && selectedFilterField.dataType === FilterDataTypes.NUMBER;
        const config = filterOperatorConfigs[newOperator];
        const newOperatorValues = getNewOperatorValues(config);
        setSelectedOperator(config);
        setNewFilter({
            ...newFilter,
            operator: newOperator,
            ...(config.numberOfParams === 0 || shouldResetValues
                ? { values: [] }
                : newOperatorValues ? { values: newOperatorValues } : {})
        });
        if (config.numberOfParams === 0 || shouldResetValues) setSelectedValues([]);
        if(newOperatorValues) setSelectedValues(newOperatorValues);
    }

    const getNewOperatorValues = (newOperatorValue: OperatorConfig) => {
        if(newOperatorValue?.numberOfParams  && newFilter?.values && newFilter.values.length > 0) {
            const numberOfFields = newOperatorValue?.numberOfParams ?? 1;
            if(newFilter.values.length < numberOfFields) {
                const diff = numberOfFields - newFilter.values.length;
                const newValues: SourceCriteriaFilterValue[] = Array.from({ length: diff }).map(() => ({
                    value: ""
                }));
                return [
                    ...newFilter.values,
                    ...newValues
                ]
            }
            return Array.from({ length: numberOfFields }).map((_, index) => (newFilter?.values?.[index] ?? { value: "" }));
        }
    }

    return (
        <>
            {operators.length === 1 &&
                <span className="min-w-fit w-fit">
                    {getOperatorName(selectedFilterField?.identifier, selectedOperator)}
                </span>
            }
            {operators.length === 2 &&
                <Radio
                    className="min-w-fit w-fit flex gap-2 h-[36px]"
                    items={filterOperators.map(operator => ({ value: operator.id, description: getOperatorName(selectedFilterField?.identifier, operator) }))}
                    value={selectedOperator?.id}
                    name={selectedFilterField.identifier}
                    defaultValue={filterOperators[0]?.id}
                    onValueChange={onOperatorChange} />
            }
            {operators.length > 2 &&
                <div className="min-w-fit w-[15%]">
                    <DropdownMenu open={selectOpen} onOpenChange={setSelectOpen}>
                        <DropdownMenuTrigger asChild>
                            <div className="flex items-center p-2 border border-ui-300/[0.32] group rounded-md h-[36px] cursor-pointer aria-expanded:outline aria-expanded:outline-2 aria-expanded:outline-blue-300 aria-expanded:border-0">
                                <span className="text-14 text-ui-700">{getOperatorName(selectedFilterField?.identifier, selectedOperator)}</span>
                                <ChevronDownIcon className="ml-auto transition-transform duration-200 group-aria-expanded:rotate-180" />
                            </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="bg-white border-0 data-[side=top]:shadow-dropdown-t data-[side=bottom]:shadow-dropdown-b">
                            {filterOperators.map((operator) => {
                                return (
                                    <Tooltip
                                        content={operator.tooltip}
                                        key={operator.name}
                                        side="right"
                                        sideOffset={0}
                                        className="w-[300px]"
                                        button={{
                                            className:
                                                "w-full group font-medium hover:bg-primary/10 hover:text-primary flex items-center h-[44px] cursor-pointer",
                                        }}
                                    >
                                        <DropdownMenuItem
                                            key={operator.id}
                                            aria-selected={operator.id === selectedOperator.id}
                                            className="hover:bg-transparent px-4 !py-3 hover:text-blue-800 aria-selected:text-blue-800 text-14 w-full"
                                            onSelect={() => onOperatorChange(operator.id)}>
                                            {getOperatorName(selectedFilterField?.identifier, operator)}
                                        </DropdownMenuItem>
                                    </Tooltip>
                                )
                            })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            }
        </>
    );
}
